import React from 'react';
import { parseMarkdown } from '../../../utils/helper';

export const ResumeSummary = ({ data = {}, version }) => {
    return version > 4 ? (
        <div className="candidate-skills-wrapper-v2">
            <span className="candidate-skills-heading candidate-skill-flex">
                <div className="resume-summary-text">Resume Summary</div>
                <div className="resume-score-header">
                    Resume Score:{' '}
                    <span>{` ${data?.overallFeedback?.[1]?.value ? `${data?.overallFeedback[1]?.value * 10}%` : 'N.A.'}`}</span>
                </div>
            </span>
            <div className="font-style-soft-skill resume-summary text-start ps-3">
                <div
                    dangerouslySetInnerHTML={{
                        __html: parseMarkdown(
                            data?.resumeSummary || 'No valid candidate information provided in the resume.'
                        ),
                    }}
                />
            </div>
        </div>
    ) : (
        <div className="candidate-skills-wrapper">
            <span className="candidate-skills-heading">Resume Summary</span>
            <div className="font-style-soft-skill resume-summary text-start">
                <div
                    dangerouslySetInnerHTML={{
                        __html: parseMarkdown(
                            data?.resumeSummary || 'No valid candidate information provided in the resume.'
                        ),
                    }}
                />
            </div>
        </div>
    );
};
