import React from 'react';
import { useSelector } from 'react-redux';
import FlipCountdown from '@rumess/react-flip-countdown';
import { Box, Flex, Text } from '@100mslive/roomkit-react';
import clock from '../../../assets/images/clock.svg';
import logo from '../../../images/incruiter-logo.svg';
const EarlyJoin = ({ remainingTime }) => {
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    return (
        <Flex direction="column" css={{ height: '100vh', width: '100vw' }}>
            <Box css={{ h: '$18', '@md': { h: '$17' }, position: 'absolute', 'z-index': 1 }} data-testid="header">
                <Flex justify="between" align="center" css={{ position: 'relative', height: '100%' }}>
                    <div style={{ marginLeft: '20px', marginTop: '20px' }}>
                        {!fetchingWhiteLabelStatus && (
                            <img
                                src={whiteLabelLogos?.whiteLogo || logo}
                                alt="InCruiter Logo"
                                style={{ width: '120px', height: 'auto' }}
                            />
                        )}
                    </div>
                </Flex>
            </Box>
            <Flex
                justify="center"
                direction="column"
                align="center"
                css={{ bg: '#191924', flex: '1 1 0', position: 'relative' }}
                className="early-join"
            >
                <div className="center-img-div d-center">
                    <img src={clock} alt="Early Join"></img>
                </div>
                <Text
                    variant="body1"
                    css={{
                        color: '#ffffff',
                        fontWeight: '$regular',
                        'font-size': '30px',
                        'line-height': '41px',
                        textAlign: 'center',
                        margin: '10px 0px',
                        '@sm': { 'font-size': '14px', 'line-height': '20px' },
                    }}
                >
                    You are too early to join this Interview.
                </Text>
                <Text
                    variant="body1"
                    css={{
                        color: '#ffffff',
                        fontWeight: '$regular',
                        'font-size': '30px',
                        'line-height': '41px',
                        textAlign: 'center',
                        margin: '10px 0px',
                        '@sm': { 'font-size': '14px', 'line-height': '20px' },
                    }}
                >
                    Time remaining to join interview
                </Text>
                <div className="early-time-div-wrapper">
                    <FlipCountdown
                        size="large"
                        hideYear
                        titlePosition="bottom"
                        hideMonth
                        endAtZero
                        endAt={remainingTime}
                    />
                </div>
            </Flex>
        </Flex>
    );
};

export default EarlyJoin;
