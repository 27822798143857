import React, { memo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const RoundBarChart = memo(({ percentage }) => {
    const colors = ['#133F7D', '#E6E9F8'];
    const totalPercentage = 10;
    const dataSet = {
        datasets: [
            {
                data: [percentage, totalPercentage - percentage],
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    };

    const options = {
        maintainAspectRatio: true,
        cutout: '77%',
        events: [],
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
            datalabels: {
                display: false, // Ensures no data labels are shown
            },
        },
    };

    return (
        <div className="position-relative">
            <Doughnut id="PercentageChart" data={dataSet} options={options} />
            <div className="resumePercentage position-absolute">
                <span>{percentage ? percentage * 10 + '%' : 'N.A.'}</span>
            </div>
        </div>
    );
});

export default RoundBarChart;
