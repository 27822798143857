import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { Flex } from '@100mslive/roomkit-react';
import { verifyRoom } from '../../../../redux/action';
import FullPageProgress from '../../../FullPageProgress';
import { ToastManager } from '../../../Toast/ToastManager';
import CanceledInterview from '../../CanceledInterview';
import { Completed } from '../Completed';
import EarlyJoin from '../EarlyJoin';
import { LinkExpired } from '../LinkExpired';
import MobileDeviceUnsupported from '../mobile/MobileDeviceUnsupported';
import Avatar from './Avatar';
import CandidateOtp from './CandidateOtp';
import InstructionInfo from './InstructionInfo';
import InterviewStartHeader from './InterviewStartHeader';
import { isMobileOrTabletDevice } from '../../../../utils/helper';
import './InterviewStart.css';
const InterviewStart = () => {
    const video2 = 'https://storage.googleapis.com/incruiter-recording-prod/files/incbot/candidateOtp.webm';
    const video3 = 'https://storage.googleapis.com/incruiter-recording-prod/files/incbot/instructions.webm';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { roomId } = useParams();
    const loading = useSelector((state) => state.Interview.loading);
    const roomVerifyStatus = useSelector((state) => state.Interview?.roomVerifyStatus);
    const [form, setForm] = useState({
        otp: '',
        otpVerified: false,
        instructionAccepted: false,
        interviewId: '',
        questionId: '',
        candidateName: '',
        jobDescription: '',
        positionTitle: '',
        companyName: '',
        candidateEmail: '',
        candidatePhone: '',
        totalDuration: '',
        totalQuestion: '',
        consent: false,
    });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [linkNotStarted, setLinkNotStarted] = useState(false);
    const [remainingTime, setRemainingTime] = useState('');
    const GENERAL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
    const canRef = useRef(null);
    const [heightValue, setHeightValue] = useState(550);

    useEffect(() => {
        if (canRef && canRef?.current) {
            setHeightValue(550 + (canRef?.current?.offsetHeight > 410 ? canRef?.current?.offsetHeight - 400 : 0));
        }
    }, [screenHeight, screenWidth, canRef]);

    useEffect(() => {
        const handleResize = debounce(() => {
            if (window.innerWidth !== screenWidth || window.innerHeight !== screenHeight) {
                setScreenWidth(window.innerWidth);
                setScreenHeight(window.innerHeight);
            }
        }, 300);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth, screenHeight]);

    useEffect(() => {
        if (roomId) {
            dispatch(verifyRoom({ roomId }));
        }
    }, [dispatch, roomId]);

    useEffect(() => {
        if (roomVerifyStatus) {
            setForm((prevState) => ({
                ...prevState,
                interviewId: roomVerifyStatus?._id,
                candidateName: roomVerifyStatus?.candidate?.name,
                jobDescription: roomVerifyStatus?.position?.jobDescription,
                positionTitle: roomVerifyStatus?.position?.title,
                companyName: roomVerifyStatus?.companyName,
                candidateEmail: roomVerifyStatus?.candidate?.email,
                candidatePhone: roomVerifyStatus?.candidate?.phone,
                totalDuration: roomVerifyStatus?.question?.totalDuration,
                totalQuestion: roomVerifyStatus?.question?.totalQuestion,
                questionId: roomVerifyStatus?.question?._id,
            }));
        }
    }, [roomVerifyStatus]);

    const handleJoin = debounce(() => {
        if (form?.instructionAccepted && form?.consent) {
            navigate(`/preview/candidate/${roomId}`, { state: form });
        } else if (!form?.consent) {
            ToastManager.addToast({
                title: 'Please give the consent to record the interview.',
            });
        } else {
            ToastManager.addToast({
                title: 'Please accept the instructions to continue.',
            });
        }
    }, 300);

    useEffect(() => {
        if (roomVerifyStatus?.startAt) {
            const startDate = new Date(roomVerifyStatus.startAt);
            const currentDate = new Date();
            setLinkNotStarted(startDate > currentDate);
            setRemainingTime(startDate > currentDate ? moment(startDate).format(GENERAL_DATE_FORMAT) : '');
        }
    }, [roomVerifyStatus?.startAt]);

    useEffect(() => {
        const handleStopCameraStream = async () => {
            try {
                const devices = await navigator?.mediaDevices?.enumerateDevices();
                if (!devices) return;
                const cameras = devices?.filter((device) => device.kind === 'videoinput');
                cameras?.forEach(async (device) => {
                    try {
                        const stream = await navigator?.mediaDevices?.getUserMedia({
                            video: { deviceId: device.deviceId },
                        });
                        if (!stream) return;
                        const tracks = stream?.getTracks();
                        tracks.forEach((track) => track?.stop());
                    } catch (error) {
                        console.error('Error stopping camera stream:', error);
                    }
                });
            } catch (error) {
                console.error('Error fetching media devices:', error);
            }
        };
        if (
            roomVerifyStatus &&
            (roomVerifyStatus.interviewStatus === 'Canceled' ||
                roomVerifyStatus.roomExpired ||
                roomVerifyStatus?.positionClosed ||
                roomVerifyStatus?.alreadyAttempted ||
                !roomVerifyStatus.status)
        )
            handleStopCameraStream();
    }, [roomVerifyStatus]);

    useEffect(() => {
        if (form.otpVerified) setHeightValue(650);
    }, [form.otpVerified]);

    if (isMobileOrTabletDevice() && roomVerifyStatus?.isCodingQuestionAvailable) return <MobileDeviceUnsupported />;
    if (loading || !roomVerifyStatus) return <FullPageProgress />;

    return roomVerifyStatus?.interviewStatus === 'Canceled' ? (
        <Flex css={{ size: '100%', background: '#2e2e41' }} direction="column">
            <CanceledInterview />
        </Flex>
    ) : roomVerifyStatus?.roomExpired || roomVerifyStatus?.positionClosed || roomVerifyStatus?.alreadyAttempted ? (
        <Flex css={{ size: '100%', background: '#2e2e41' }} direction="column">
            <LinkExpired
                message={
                    roomVerifyStatus?.positionClosed
                        ? 'Position has been closed.'
                        : roomVerifyStatus?.alreadyAttempted
                          ? 'You have already attempted for the interview.'
                          : 'The interview link has expired.'
                }
            />
        </Flex>
    ) : linkNotStarted ? (
        <EarlyJoin remainingTime={remainingTime} />
    ) : roomVerifyStatus?.status ? (
        <div className="interview-container">
            <InterviewStartHeader />
            <div className={`${screenHeight > heightValue ? 'instruction-container-main' : ''}`}>
                <div
                    className={`instruction-container ${screenHeight > heightValue ? 'w-85vw' : ''} ${form?.otpVerified ? ' instruction-info-dev' : ' instruction-flex'}`}
                >
                    {form?.otpVerified ? (
                        <InstructionInfo form={form} setForm={setForm} handleJoin={handleJoin} />
                    ) : (
                        <CandidateOtp form={form} setForm={setForm} canRef={canRef} />
                    )}
                    {!isMobileOrTabletDevice() && <Avatar video={form?.otpVerified ? video3 : video2} />}
                </div>
            </div>
        </div>
    ) : (
        <Flex css={{ size: '100%', background: '#2e2e41' }} direction="column">
            <Completed />
        </Flex>
    );
};

export default InterviewStart;
