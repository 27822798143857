import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { NetworkConnectionLost } from './Pages/components/NetworkConnectionLost';
import PracticeDesktop from './Pages/Practice/PracticeDesktop';
import PracticeMobile from './Pages/Practice/PracticeMobile';
import { isMobileOrTabletDevice, practiceQuestion } from '../utils/helper';

const PracticeRoom = React.memo(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const hasCheckedStatus = useRef(false);
    const { roomId, role } = useParams();
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [onlineStatus, setOnlineStatus] = useState(true);
    const [audioPlayer, setAudioPlayer] = useState(null);
    const [answerDuration, setAnswerDuration] = useState(0);
    const [interviewStarted, setInterviewStarted] = useState(false);
    const [questionInfo, setQuestionInfo] = useState({
        question: '',
        questionAnswer: '',
        prepareTime: 0,
        timeToAnswer: 0,
        questionNumber: 0,
        actualQuestion: '',
        domain: '',
        questionType: '',
        audioUrl: '',
    });
    const [questionInput, setQuestionInput] = useState({
        interviewId: '',
        questionId: '',
        questionNumber: 1,
    });
    const [form] = useState(location?.state?.form);
    const token = location?.state?.token;
    const [isPlaying, setIsPlaying] = useState(false);
    const [prepareTime, setPrepareTime] = useState(0);
    const [answerTime, setAnswerTime] = useState(0);
    const [waitingPrepTime, setWaitingPrepTime] = useState(false);
    const [waitingAnswerTime, setWaitingAnswerTime] = useState(false);
    const [displayInput, setDisplayInput] = useState({
        message: '',
        audio: '',
        displayText: '',
    });
    const [popupInputs, setPopupInputs] = useState({
        isDisabled: true,
        questionNumber: 0,
        form: null,
        isNextClick: false,
        idealPopup: false,
        questionType: '',
    });
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        if (!questionInput.questionNumber) return;
        const handleSubmission = async () => {
            if (!isUpdated) {
                await handleSubmit(isMobileOrTabletDevice() ? 'Mobile' : 'Desktop');
                setIsUpdated(true);
            }
        };
        handleSubmission();
    }, [questionInput.questionNumber, isUpdated]);

    useEffect(() => {
        if (!hasCheckedStatus.current && !location?.state?.status) {
            navigate(`/preview/${role}/${roomId}`, { state: form });
        } else {
            hasCheckedStatus.current = true;
            navigate(location.pathname, {
                replace: true,
                state: { ...location.state, status: false },
            });
        }
    }, [location?.state?.status]);

    const handleEndCall = (isNetworkDisconnect = false) => {
        if (audioPlayer) {
            audioPlayer.pause();
            audioPlayer.currentTime = 0;
        }
        navigate(`/preview/${role}/${roomId}`, { state: form });
    };

    useEffect(() => {
        const handleTimeout = () => {
            if (!onlineStatus) {
                if (isPlaying) {
                    if (audioPlayer) {
                        audioPlayer.pause();
                    }
                    setIsPlaying(false);
                    setWaitingPrepTime(false);
                    setWaitingAnswerTime(false);
                }
                setIsNetworkError(true);
            }
        };

        const timeoutId = setTimeout(handleTimeout, 10000);
        return () => clearTimeout(timeoutId);
    }, [onlineStatus]);

    const offlineCallback = () => setOnlineStatus(false);
    const onlineCallback = () => setOnlineStatus(true);

    useEffect(() => {
        window.addEventListener('offline', offlineCallback);
        window.addEventListener('online', onlineCallback);
        return () => {
            window.removeEventListener('offline', offlineCallback);
            window.removeEventListener('online', onlineCallback);
        };
    }, []);

    const timerEnd = () => {
        setPrepareTime(0);
        setAnswerTime(0);
    };

    const handleSubmit = async (mode) => {
        setWaitingPrepTime(true);
        if (onlineStatus) {
            const data = practiceQuestion(questionInfo.questionNumber + 1);
            console.log(data);
            setQuestionInfo((prevState) => ({
                ...prevState,
                question: data?.response,
                questionAnswer: data?.questionAnswer,
                prepareTime: data?.timeToPrepare,
                timeToAnswer: data?.timeToAnswer,
                questionNumber: questionInput?.questionNumber,
                actualQuestion: data?.question,
                domain: data?.domain,
                questionType: data?.questionType,
                audioUrl: data?.audioUrl ?? '',
            }));

            const currentQuestionNumber = parseInt(questionInput?.questionNumber);
            const totalQuestions = 3;
            const questionStatus = currentQuestionNumber <= totalQuestions;

            if (questionStatus) {
                setPrepareTime(data?.timeToPrepare);
                setAnswerTime(data?.timeToAnswer);
                if (!interviewStarted) setInterviewStarted(true);
                setQuestionInput((prevState) => ({
                    ...prevState,
                    candidateResponse: '',
                    questionNumber: currentQuestionNumber + 1,
                }));
            } else {
                timerEnd();
            }

            if (mode === 'Mobile') {
                setPopupInputs((prevState) => ({
                    ...prevState,
                    questionNumber: currentQuestionNumber + 1,
                    totalQuestions,
                    questionType: data?.questionType,
                }));
            }

            setDisplayInput({
                message: data?.response,
                displayText:
                    `Question-${currentQuestionNumber}: ${data?.question}` ??
                    `Question-${currentQuestionNumber}: ${data?.response}`,
                audio: data?.audioUrl ?? '',
            });

            setAnswerDuration(0);
        }
    };

    const handleAudioEnd = () => {
        setIsPlaying(false);
        setWaitingPrepTime(false);
        setWaitingAnswerTime(false);
        if (audioPlayer) {
            audioPlayer.removeEventListener('ended', handleAudioEnd);
            audioPlayer.removeEventListener('error', handleAudioError);
        }
    };

    const handleAudioError = (error) => {
        setIsPlaying(false);
        setWaitingPrepTime(false);
        setWaitingAnswerTime(false);
        console.error('Audio playback error:', error);
        if (audioPlayer) {
            audioPlayer.removeEventListener('ended', handleAudioEnd);
            audioPlayer.removeEventListener('error', handleAudioError);
        }
    };

    const playAudio = async () => {
        if (!displayInput.audio) return;
        const audio = new Audio(displayInput.audio);
        setAudioPlayer(audio);
        audio.addEventListener('ended', handleAudioEnd);
        audio.addEventListener('error', handleAudioError);
        try {
            await audio.play();
            setIsPlaying(true);
        } catch (error) {
            console.log(error);
            handleAudioError(error);
        }
        const handleUnload = () => {
            audio.pause();
            audio.currentTime = 0;
        };
        window.addEventListener('beforeunload', handleUnload);
        window.addEventListener('popstate', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
            window.removeEventListener('popstate', handleUnload);
        };
    };

    useEffect(() => {
        if (audioPlayer) {
            const interval = setInterval(() => {
                if (audioPlayer.paused) {
                    clearInterval(interval);
                    setIsPlaying(false);
                    setWaitingPrepTime(false);
                    setWaitingAnswerTime(false);
                }
            }, 100);
            return () => clearInterval(interval);
        }
    }, [audioPlayer]);

    const skipTimer = () => {
        setPrepareTime(0);
    };

    useEffect(() => {
        if (onlineStatus && isNetworkError) handleEndCall(true);
    }, [onlineStatus, isNetworkError]);

    return isNetworkError ? (
        <NetworkConnectionLost inInterviewRoom />
    ) : isMobileOrTabletDevice() ? (
        <PracticeMobile
            form={{ ...form, totalQuestion: 3 }}
            roomId={roomId}
            questionInfo={questionInfo}
            isPlaying={isPlaying}
            prepareTime={prepareTime}
            setPrepareTime={setPrepareTime}
            answerTime={answerTime}
            setAnswerTime={setAnswerTime}
            handleEndCall={handleEndCall}
            interviewStarted={interviewStarted}
            onlineStatus={onlineStatus}
            setAnswerDuration={setAnswerDuration}
            answerDuration={answerDuration}
            handleSubmit={handleSubmit}
            displayInput={displayInput}
            popupInputs={popupInputs}
            setPopupInputs={setPopupInputs}
            playAudio={playAudio}
            skipTimer={skipTimer}
            waitingPrepTime={waitingPrepTime}
            setDisplayInput={setDisplayInput}
            token={token}
        />
    ) : (
        <PracticeDesktop
            form={{ ...form, totalQuestion: 3 }}
            questionInfo={questionInfo}
            isPlaying={isPlaying}
            prepareTime={prepareTime}
            setPrepareTime={setPrepareTime}
            answerTime={answerTime}
            setAnswerTime={setAnswerTime}
            handleEndCall={handleEndCall}
            interviewStarted={interviewStarted}
            onlineStatus={onlineStatus}
            setAnswerDuration={setAnswerDuration}
            answerDuration={answerDuration}
            handleSubmit={handleSubmit}
            displayInput={displayInput}
            setDisplayInput={setDisplayInput}
            isUpdated={isUpdated}
            playAudio={playAudio}
            skipTimer={skipTimer}
            waitingPrepTime={waitingPrepTime}
            waitingAnswerTime={waitingAnswerTime}
            setWaitingAnswerTime={setWaitingAnswerTime}
            timerEnd={timerEnd}
            token={token}
        />
    );
});

export default PracticeRoom;
