import axios from 'axios';
import { generateUrl } from '../helper';
const contextHandler = axios.create({
    baseURL: generateUrl('context-handler'),
});

contextHandler.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

contextHandler.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default contextHandler;
