import React from 'react';
import { OverAllSkill } from './components/OverAllSkill';
import { ResumeSummary } from './components/ResumeSummary';
export const OverallSkills = ({ data, version }) => {
    return version < 5 ? (
        <div className="overall-skills-div break-stop">
            <ResumeSummary data={data} version={version} />
            <OverAllSkill data={data} />
        </div>
    ) : (
        <div className="overall-skills-div-v2 break-stop">
            <ResumeSummary data={data} version={version} />
        </div>
    );
};
