import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the components and plugins
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const BarChart = ({ data }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        // Attach the event listener to update dimensions when the window is resized
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const specificMarks = data?.positionSpecificQuestionStats?.obtainedMarks;
    const specificMarksTotal = data?.positionSpecificQuestionStats?.totalMarks;
    const codingMarks = data?.codingQuestionStats?.obtainedMarks;
    const codingMarksTotal = data?.codingQuestionStats?.totalMarks;
    const scoredMarks =
        codingMarks && specificMarks
            ? codingMarks + specificMarks
            : specificMarks
              ? specificMarks
              : codingMarks
                ? codingMarks
                : 0;
    const specificPercentage = data?.positionSpecificQuestionStats?.percentage;
    const codingPercentage = data?.codingQuestionStats?.percentage;
    const totalMarks =
        codingMarksTotal && specificMarksTotal
            ? codingMarksTotal + specificMarksTotal
            : specificMarksTotal
              ? specificMarksTotal
              : codingMarksTotal
                ? codingMarksTotal
                : 0;
    const percentage = (scoredMarks / totalMarks) * 100;
    const labels = [];
    const dataset = [[], [], []];
    if (data?.positionSpecificQuestionStats?.totalMarks) {
        labels.push('Position Specific');
        dataset[0].push(specificMarks);
        dataset[1].push(specificMarksTotal);
        dataset[2].push(specificPercentage);
    }
    if (data?.codingQuestionStats?.totalMarks) {
        labels.push('Coding');
        dataset[0].push(codingMarks);
        dataset[1].push(codingMarksTotal);
        dataset[2].push(codingPercentage);
    }
    labels.push('Total');
    dataset[0].push(scoredMarks);
    dataset[1].push(totalMarks);
    dataset[2].push(Math.round(percentage * 10) / 10);

    const dataGraph = {
        labels: labels,
        datasets: [
            {
                label: 'Scored Marks',
                data: dataset[0],
                backgroundColor: '#133F7D', // Dark Blue color
                barThickness: 30,
                hoverBackgroundColor: '#133F7D', // To remove hover effect
            },
            {
                label: 'Total Marks',
                data: dataset[1],
                backgroundColor: '#11B2B8', // Teal color
                barThickness: 30,
                hoverBackgroundColor: '#11B2B8', // To remove hover effect
            },
            {
                label: 'Percentage',
                data: dataset[2],
                backgroundColor: '#005EE2', // Blue color
                barThickness: 30,
                hoverBackgroundColor: '#005EE2', // To remove hover effect
            },
        ],
    };

    // Chart options
    const options = {
        responsive: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    usePointStyle: true,
                    padding: 10,
                },
            },
            tooltip: {
                enabled: false, // Disable tooltip to remove hover effect
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'end',
                formatter: (value) => value, // Display the value
                font: {
                    size: 10, // Adjust font size if needed
                    weight: 'bold',
                },
                padding: {
                    top: -15, // Adjust padding to place labels above the bars
                },
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                grid: {
                    display: false, // Hide vertical grid lines
                },
                border: {
                    display: false, // Remove the border line on the left
                },
            },
            y: {
                beginAtZero: true,
                // max: 100,
                border: {
                    dash: [12, 6],
                },
                grid: {
                    display: true, // Display horizontal grid lines
                    color: '#e0e0e0', // Light gray color for grid lines
                    borderDash: [5, 5], // Dashed lines for horizontal grid
                },
                ticks: {
                    display: false, // Hide Y-axis labels and ticks
                    stepSize: 20,
                },
                border: {
                    display: false, // Remove Y-axis vertical line
                },
            },
        },
        // To remove the hover effect on bars
        hover: {
            mode: null,
        },
    };

    return (
        <div className="data-matrix-main">
            <div className="bar-chart-main">
                <Bar
                    id = "SectionWiseBar"
                    data={dataGraph}
                    options={options}
                    height={300}
                    width={350}
                    className={screenWidth > 400 ? 'bar-chart-size-v1' : 'bar-chart-size-v2'}
                />
                <div className="bar-chart-title">Section wise Overall Score</div>
            </div>
        </div>
    );
};

export default BarChart;
