import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { resendOtpRequest, verifyCandidateKey } from '../../../../redux/action';
import { ToastManager } from '../../../Toast/ToastManager';
import Button from '../../../commonComponent/Button';
import { isMobileOrTabletDevice } from '../../../../utils/helper';
const CandidateOtp = ({ form, setForm, canRef }) => {
    const [resendDisabled, setResendDisabled] = useState(false);
    const [otp, setOtp] = useState('');
    const [countdown, setCountdown] = useState(0);
    const submitLoading = useSelector((state) => state.Interview.verifyLoading);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!resendDisabled) return;
        const interval = setInterval(() => {
            setCountdown((prev) => (prev > 1 ? prev - 1 : clearInterval(interval) || setResendDisabled(false)));
        }, 1000);
        return () => clearInterval(interval);
    }, [resendDisabled]);

    const handleResendClick = useCallback(() => {
        if (!resendDisabled) {
            setResendDisabled(true);
            setCountdown(30);
            dispatch(resendOtpRequest({ interviewId: form?.interviewId }));
        }
    }, [dispatch, form?.interviewId, resendDisabled]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') handleSubmit();
    };

    const handleOtpChange = (otpValue) => {
        setOtp(otpValue);
    };

    const handleSubmit = debounce(() => {
        if (otp?.length === 4) {
            dispatch(
                verifyCandidateKey({
                    form: { interviewId: form?.interviewId, key: otp },
                    callback: (status) => {
                        if (status) {
                            setForm({
                                ...form,
                                otp: otp,
                                otpVerified: true,
                            });
                            ToastManager.addToast({
                                title: 'OTP verified successfully.',
                            });
                        } else {
                            ToastManager.addToast({
                                title: 'Invalid OTP!',
                            });
                        }
                    },
                })
            );
        } else {
            ToastManager.addToast({
                title: 'Please enter the valid OTP.',
            });
        }
    }, 300);

    const Loader = memo(() => {
        return (
            <div className="wrapper-4">
                <div className="loader"></div>
            </div>
        );
    });

    return (
        <div className="instruction-container-box">
            {submitLoading && <Loader />}
            <div
                ref={canRef}
                className={`interview-candidate-instruction${isMobileOrTabletDevice() ? ' otp-div-center' : ''}`}
            >
                <div className="instruction-title">Let's get started!</div>
                <p className="instruction-subtitle">Please verify before starting</p>
                <div className="content-container verify-content-container">
                    <InfoSection form={form} />
                    <OtpSection
                        otp={otp || ''}
                        onChange={handleOtpChange}
                        handleKeyDown={handleKeyDown}
                        handleResendClick={handleResendClick}
                        countdown={countdown}
                        resendDisabled={resendDisabled}
                        handleSubmit={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

const InfoSection = ({ form }) => (
    <div className="info-section">
        <p>Hello {form?.candidateName}!</p>
        <p>
            Welcome to the Interview for <span>{form?.companyName}</span>!
        </p>
        <p>
            Job Position:{' '}
            {form?.jobDescription ? (
                <span className="blue-text">
                    <a href={form?.jobDescription} title={form?.positionTitle} target="_blank" rel="noreferrer">
                        {form?.positionTitle}
                    </a>
                </span>
            ) : (
                <span>{form?.positionTitle}</span>
            )}
        </p>
        <p>
            <span>Email Id: </span>
            {form?.candidateEmail}
        </p>
        <p>
            <span>Whatsapp No.: </span>+{form?.candidatePhone}
        </p>
    </div>
);

const OtpSection = ({ otp, onChange, handleKeyDown, handleResendClick, countdown, resendDisabled, handleSubmit }) => (
    <div className="otp-section">
        <h3 className="otp-title">Enter OTP received on Email ID or Whatsapp no.</h3>
        <div className="otp-input-container" onKeyDown={handleKeyDown}>
            <OtpInput value={otp} onChange={onChange} />
        </div>
        <center>
            <button
                onClick={handleResendClick}
                disabled={resendDisabled}
                className={`blue-text resend-btn fw-5 mt-3 text-center f-12 ${resendDisabled ? ' disabled-text' : ''}`}
            >
                Resend code{countdown > 1 ? ` (0:${countdown})` : ''}
            </button>
        </center>
        <Button
            text="Verify"
            className="candidate-btn"
            onClick={debounce(() => handleSubmit(), 300)}
            disabled={!(otp && otp?.length === 4)}
        />
    </div>
);

const OtpInput = ({ value, onChange, numInputs = 4 }) => {
    const [otp, setOtp] = useState(Array(numInputs).fill(''));

    useEffect(() => {
        if (value.length === numInputs) setOtp(value.split(''));
    }, [value, numInputs]);

    const handleChange = (e, index) => {
        const updatedOtp = [...otp];
        if (/^\d?$/.test(e.target.value)) {
            updatedOtp[index] = e.target.value;
            setOtp(updatedOtp);
            onChange(updatedOtp.join(''));
            if (e.target.value && index < numInputs - 1) document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0)
            document.getElementById(`otp-input-${index - 1}`).focus();
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text').trim();
        if (/^\d{4}$/.test(paste)) {
            setOtp(paste.split(''));
            onChange(paste);
        }
    };

    return (
        <div className="otp-input-container" style={{ display: 'flex', justifyContent: 'center' }}>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="tel"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                    className="otp-input"
                    maxLength="1"
                    inputMode="numeric"
                />
            ))}
        </div>
    );
};

export default CandidateOtp;
