import {
    CANDIDATE_CALL_END,
    CANDIDATE_CALL_END_FAILURE,
    CANDIDATE_CALL_END_SUCCESS,
    CANDIDATE_CALL_START,
    CANDIDATE_CALL_START_FAILURE,
    CANDIDATE_CALL_START_SUCCESS,
    CONTACT_ADMIN,
    CONTACT_ADMIN_FAILURE,
    CONTACT_ADMIN_SUCCESS,
    CREATE_AND_UPDATE_SCREENSHOT,
    CREATE_AND_UPDATE_SCREENSHOT_FAILURE,
    CREATE_AND_UPDATE_SCREENSHOT_SUCCESS,
    CREATE_CODE_SUBMISSION,
    CREATE_CODE_SUBMISSION_FAILURE,
    CREATE_CODE_SUBMISSION_SUCCESS,
    FETCH_SCREENSHOT_IMAGE,
    FETCH_SCREENSHOT_IMAGE_FAILURE,
    FETCH_SCREENSHOT_IMAGE_SUCCESS,
    FULL_FACE_DETECTION,
    FULL_FACE_DETECTION_FAILURE,
    FULL_FACE_DETECTION_NEW,
    FULL_FACE_DETECTION_NEW_FAILURE,
    FULL_FACE_DETECTION_NEW_SUCCESS,
    FULL_FACE_DETECTION_SUCCESS,
    GET_INTERVIEW,
    GET_INTERVIEW_FAILURE,
    GET_INTERVIEW_SUCCESS,
    GET_INTERVIEW_TOKEN,
    GET_INTERVIEW_TOKEN_FAILURE,
    GET_INTERVIEW_TOKEN_SUCCESS,
    GET_QUESTION,
    GET_QUESTION_FAILURE,
    GET_QUESTION_NUMBER,
    GET_QUESTION_NUMBER_FAILURE,
    GET_QUESTION_NUMBER_SUCCESS,
    GET_QUESTION_SUCCESS,
    INTERVIEW_FEEDBACK_STATUS,
    INTERVIEW_FEEDBACK_STATUS_FAILURE,
    INTERVIEW_FEEDBACK_STATUS_SUCCESS,
    LOOKING_FORWARD,
    LOOKING_FORWARD_FAILURE,
    LOOKING_FORWARD_SUCCESS,
    MOVEMENT,
    MOVEMENT_FAILURE,
    MOVEMENT_SUCCESS,
    NOTIFICATION_ERROR,
    NOTIFICATION_ERROR_FAILURE,
    NOTIFICATION_ERROR_SUCCESS,
    POST_CANDIDATE,
    POST_CANDIDATE_FAILURE,
    POST_CANDIDATE_SUCCESS,
    POST_INTERVIEW_FEEDBACK,
    POST_INTERVIEW_FEEDBACK_FAILURE,
    POST_INTERVIEW_FEEDBACK_SUCCESS,
    POST_INTERVIEW_STATUS,
    POST_INTERVIEW_STATUS_FAILURE,
    POST_INTERVIEW_STATUS_SUCCESS,
    PRACTICE_QUESTION,
    PRACTICE_QUESTION_FAILURE,
    PRACTICE_QUESTION_SUCCESS,
    PRACTICE_QUESTION_UPDATE,
    PRACTICE_QUESTION_UPDATE_FAILURE,
    PRACTICE_QUESTION_UPDATE_SUCCESS,
    REMOVE_SCREENSHOT_IMAGE,
    RESEND_OTP_REQUEST,
    RESEND_OTP_REQUEST_FAILURE,
    RESEND_OTP_REQUEST_SUCCESS,
    RESET_CODE_SUBMISSION_OUTPUT,
    SUBMIT_CODING_QUESTION,
    SUBMIT_CODING_QUESTION_FAILURE,
    SUBMIT_CODING_QUESTION_SUCCESS,
    SUBMIT_TRANSCRIBE,
    SUBMIT_TRANSCRIBE_FAILURE,
    SUBMIT_TRANSCRIBE_SUCCESS,
    SWITCH_TAB,
    SWITCH_TAB_FAILURE,
    SWITCH_TAB_SUCCESS,
    UPLOAD_VIDEO,
    VERIFY_CANDIDATE_KEY,
    VERIFY_CANDIDATE_KEY_FAILURE,
    VERIFY_CANDIDATE_KEY_SUCCESS,
    VERIFY_ROOM,
    VERIFY_ROOM_FAILURE,
    VERIFY_ROOM_SUCCESS,
} from '../action/types';

const INIT_STATE = {
    apiFailure: false,
    loading: false,
    screenshot: null,
    question: null,
    interviewToken: null,
    interview: null,
    verifyKey: null,
    fetchImage: null,
    resendOtp: null,
    roomVerifyStatus: null,
    interviewFeedbackStatusDetails: null,
    hasFullFaceDetected: false,
    hasFullFaceDetectedNew: false,
    fetchingWhiteLabelStatus: false,
    whiteLabelLogos: {
        whiteLogo: '',
        logo: '',
    },
    submitLoading: false,
    codeSubmissionOutput: null,
    verifyLoading: false,
    screenshotImage: null,
};

const Interview = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NOTIFICATION_ERROR:
            return { ...state, loading: true };
        case NOTIFICATION_ERROR_SUCCESS:
            return { ...state, loading: false };
        case NOTIFICATION_ERROR_FAILURE:
            return { ...state, loading: false };

        case PRACTICE_QUESTION:
            return { ...state, loading: false };
        case PRACTICE_QUESTION_SUCCESS:
            return { ...state, loading: false };
        case PRACTICE_QUESTION_FAILURE:
            return { ...state, loading: false };

        case PRACTICE_QUESTION_UPDATE:
            return { ...state, loading: false };
        case PRACTICE_QUESTION_UPDATE_SUCCESS:
            return { ...state, loading: false };
        case PRACTICE_QUESTION_UPDATE_FAILURE:
            return { ...state, loading: false };

        case INTERVIEW_FEEDBACK_STATUS:
            return { ...state, loading: true, fetchingWhiteLabelStatus: true };
        case INTERVIEW_FEEDBACK_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewFeedbackStatusDetails: action.payload,
                fetchingWhiteLabelStatus: false,
                whiteLabelLogos: action.payload.checkWhiteLabelledStatus.whiteLabelStatus
                    ? {
                          whiteLogo: action.payload.checkWhiteLabelledStatus.data.companyWhiteLogo,
                          logo: action.payload.checkWhiteLabelledStatus.data.companyLogo,
                      }
                    : {
                          whiteLogo: '',
                          logo: '',
                      },
            };
        case INTERVIEW_FEEDBACK_STATUS_FAILURE:
            return { ...state, loading: false, fetchingWhiteLabelStatus: true };

        case SUBMIT_TRANSCRIBE:
            return { ...state, loading: false };
        case SUBMIT_TRANSCRIBE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case SUBMIT_TRANSCRIBE_FAILURE:
            return { ...state, loading: false };

        case MOVEMENT:
            return { ...state, loading: true };
        case MOVEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case MOVEMENT_FAILURE:
            return { ...state, loading: false };

        case CREATE_AND_UPDATE_SCREENSHOT:
            return { ...state, loading: true };
        case CREATE_AND_UPDATE_SCREENSHOT_SUCCESS:
            return {
                ...state,
                loading: false,
                screenshot: action.payload,
            };
        case CREATE_AND_UPDATE_SCREENSHOT_FAILURE:
            return { ...state, loading: false };

        case SWITCH_TAB:
            return { ...state, loading: false };
        case SWITCH_TAB_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case SWITCH_TAB_FAILURE:
            return { ...state, loading: false };

        case CANDIDATE_CALL_START:
            return { ...state, loading: true };
        case CANDIDATE_CALL_START_FAILURE:
            return {
                ...state,
                loading: false,
                apiFailure: CANDIDATE_CALL_START,
            };
        case CANDIDATE_CALL_START_SUCCESS:
            return { ...state, loading: false, verifyKey: null, roomVerifyStatus: null };
        case VERIFY_CANDIDATE_KEY:
            return { ...state, verifyLoading: true };
        case VERIFY_CANDIDATE_KEY_SUCCESS:
            return {
                ...state,
                verifyLoading: false,
                verifyKey: action.payload,
            };
        case VERIFY_CANDIDATE_KEY_FAILURE:
            return { ...state, verifyLoading: false, apiFailure: VERIFY_CANDIDATE_KEY };
        case VERIFY_ROOM:
            return { ...state, loading: true, fetchingWhiteLabelStatus: true };
        case VERIFY_ROOM_SUCCESS:
            return {
                ...state,
                loading: false,
                roomVerifyStatus: action.payload,
                fetchingWhiteLabelStatus: false,
                whiteLabelLogos: action.payload.checkWhiteLabelledStatus.whiteLabelStatus
                    ? {
                          whiteLogo: action.payload.checkWhiteLabelledStatus.data.companyWhiteLogo,
                          logo: action.payload.checkWhiteLabelledStatus.data.companyLogo,
                      }
                    : {
                          whiteLogo: '',
                          logo: '',
                      },
            };
        case VERIFY_ROOM_FAILURE:
            return { ...state, loading: false, apiFailure: VERIFY_ROOM, fetchingWhiteLabelStatus: true };
        case GET_QUESTION_NUMBER:
            return { ...state, loading: true };
        case GET_QUESTION_NUMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                questionNumber: action.payload?.completedQuestions || 0,
            };
        case GET_QUESTION_NUMBER_FAILURE:
            return { ...state, loading: false, apiFailure: GET_QUESTION_NUMBER };
        case GET_QUESTION:
            return { ...state, loading: true };
        case GET_QUESTION_SUCCESS:
            return {
                ...state,
                loading: false,
                question: action.payload,
            };
        case GET_QUESTION_FAILURE:
            return { ...state, loading: false, apiFailure: GET_QUESTION };

        case POST_INTERVIEW_STATUS:
            return { ...state, loading: true };
        case POST_INTERVIEW_STATUS_SUCCESS:
            return { ...state, loading: false };
        case POST_INTERVIEW_STATUS_FAILURE:
            return { ...state, loading: false, apiFailure: POST_INTERVIEW_STATUS };

        case CANDIDATE_CALL_END:
            return { ...state, loading: true };
        case CANDIDATE_CALL_END_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CANDIDATE_CALL_END_FAILURE:
            return { ...state, loading: false };

        case REMOVE_SCREENSHOT_IMAGE:
            return {
                ...state,
                loading: false,
                fetchImage: null,
            };

        case FETCH_SCREENSHOT_IMAGE:
            return {
                ...state,
                loading: false,
                fetchImage: null,
            };
        case FETCH_SCREENSHOT_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                fetchImage: action.payload,
            };
        case FETCH_SCREENSHOT_IMAGE_FAILURE:
            return { ...state, loading: false };

        case GET_INTERVIEW_TOKEN:
            return { ...state, loading: true, fetchingWhiteLabelStatus: true };
        case GET_INTERVIEW_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                interviewToken: action.payload,
                fetchingWhiteLabelStatus: false,
                whiteLabelLogos: action.payload.checkWhiteLabelledStatus.whiteLabelStatus
                    ? {
                          whiteLogo: action.payload.checkWhiteLabelledStatus.data.companyWhiteLogo,
                          logo: action.payload.checkWhiteLabelledStatus.data.companyLogo,
                      }
                    : {
                          whiteLogo: '',
                          logo: '',
                      },
            };
        case GET_INTERVIEW_TOKEN_FAILURE:
            return { ...state, loading: false, apiFailure: GET_INTERVIEW_TOKEN, fetchingWhiteLabelStatus: true };

        case GET_INTERVIEW:
            return { ...state, loading: true, fetchingWhiteLabelStatus: true };
        case GET_INTERVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                interview: action.payload,
                fetchingWhiteLabelStatus: false,
                whiteLabelLogos: action.payload.employer.checkWhiteLabelledStatus.whiteLabelStatus
                    ? {
                          whiteLogo: action.payload.employer.checkWhiteLabelledStatus.data.companyWhiteLogo,
                          logo: action.payload.employer.checkWhiteLabelledStatus.data.companyLogo,
                      }
                    : {
                          whiteLogo: '',
                          logo: '',
                      },
            };
        case GET_INTERVIEW_FAILURE:
            return { ...state, loading: false, apiFailure: GET_INTERVIEW, fetchingWhiteLabelStatus: true };

        case POST_INTERVIEW_FEEDBACK:
            return { ...state, loading: true };
        case POST_INTERVIEW_FEEDBACK_SUCCESS:
            return { ...state, loading: false };
        case POST_INTERVIEW_FEEDBACK_FAILURE:
            return { ...state, loading: false, apiFailure: POST_INTERVIEW_FEEDBACK };

        case RESEND_OTP_REQUEST:
            return { ...state, loading: false };
        case RESEND_OTP_REQUEST_SUCCESS:
            return { ...state, loading: false, resendOtp: action.payload };
        case RESEND_OTP_REQUEST_FAILURE:
            return { ...state, loading: false, apiFailure: RESEND_OTP_REQUEST };

        case POST_CANDIDATE:
            return { ...state, verifyLoading: true };
        case POST_CANDIDATE_SUCCESS:
            return { ...state, verifyLoading: false };
        case POST_CANDIDATE_FAILURE:
            return { ...state, verifyLoading: false, apiFailure: POST_CANDIDATE };

        case CONTACT_ADMIN:
            return { ...state, loading: true };
        case CONTACT_ADMIN_SUCCESS:
            return { ...state, loading: false };
        case CONTACT_ADMIN_FAILURE:
            return { ...state, loading: false };

        case FULL_FACE_DETECTION_NEW:
            return { ...state, loading: true };
        case FULL_FACE_DETECTION_NEW_SUCCESS:
            return { ...state, loading: false, screenshotImage: action.payload };
        case FULL_FACE_DETECTION_NEW_FAILURE:
            return { ...state, loading: false };

        case CREATE_CODE_SUBMISSION:
            return { ...state, submitLoading: true };
        case CREATE_CODE_SUBMISSION_FAILURE:
            return { ...state, submitLoading: false };
        case CREATE_CODE_SUBMISSION_SUCCESS:
            return {
                ...state,
                submitLoading: false,
                codeSubmissionOutput: action?.payload?.result,
            };
        case RESET_CODE_SUBMISSION_OUTPUT:
            return {
                ...state,
                codeSubmissionOutput: null,
            };

        case SUBMIT_CODING_QUESTION:
            return { ...state, submitLoading: action?.payload?.loader };
        case SUBMIT_CODING_QUESTION_SUCCESS:
            return { ...state, submitLoading: false };
        case SUBMIT_CODING_QUESTION_FAILURE:
            return { ...state, submitLoading: false };

        case LOOKING_FORWARD:
        case LOOKING_FORWARD_SUCCESS:
        case LOOKING_FORWARD_FAILURE:
            return { ...state };

        case UPLOAD_VIDEO:
            return { ...state };

        case FULL_FACE_DETECTION:
            return { ...state, loading: true };
        case FULL_FACE_DETECTION_SUCCESS:
            return { ...state, loading: false, hasFullFaceDetected: action.payload };
        case FULL_FACE_DETECTION_FAILURE:
            return { ...state, loading: false };

        default:
            return state;
    }
};
export default Interview;
export const getSubmitLoading = (state) => state.Interview.submitLoading;
export const getCodeSubmissionOutput = (state) => state.Interview.codeSubmissionOutput;
