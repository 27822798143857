import React from 'react';
import high from '../../../assets/images/high.svg';
import low from '../../../assets/images/low.svg';
import medium from '../../../assets/images/medium.svg';
import vectorb from '../../../assets/images/Vectorb.svg';
import vectortl from '../../../assets/images/Vectortl.svg';
import vectortr from '../../../assets/images/Vectortr.svg';
import { getSkill } from './Helpers';
import RoundBarChart from './ResumeScoreBar.jsx';
export const OverAllSkill = ({ data }) => {
    return (
        data?.overallFeedback && (
            <div className="overAll-skills">
                <div className="overAll-skills-header">
                    <span className="overAll-skills-span font-style-header">Overall Feedback</span>
                </div>
                <div className="toplayer-ellip">
                    <div>
                        <div>
                            <img
                                className="toplayer-ellip-img"
                                src={getSkill(data?.overallFeedback[0]?.value)}
                                alt="ellipse"
                            />
                            <div className="toplayer-ellip-title OverallTitle font-style-percent">
                                {data?.overallFeedback[0]?.title}
                            </div>
                        </div>
                    </div>
                    <div className="vector-wrapper">
                        <div className="vector-tl-div">
                            <img className="vector-tl" src={vectortl} alt="vectortl"></img>
                        </div>
                        <div className="vector-tl-div">
                            <img className="vector-tl" src={vectortr} alt="vectortr"></img>
                        </div>
                    </div>
                </div>
                <div className="secondlayer-ellip">
                    <div className="ellip-div">
                        <div className="toplayer-ellip-img resume">
                            <RoundBarChart percentage={data?.overallFeedback[1]?.value} />
                        </div>
                        <div className="toplayer-ellip-title OverallTitle font-style-percent">Resume Score</div>
                    </div>
                    <div className="ellip-div vector-center-div">
                        <img className="vector-center" src={vectorb} alt="vectorb"></img>
                    </div>
                    <div className="ellip-div">
                        <img
                            className="toplayer-ellip-img"
                            src={
                                data?.overallFeedback[2]?.value > 7
                                    ? high
                                    : data?.overallFeedback[2]?.value > 4
                                      ? medium
                                      : low
                            }
                            alt="ellipse"
                        />
                        <p className="toplayer-ellip-title OverallTitle font-style-percent suspicious-activity">
                            {data?.overallFeedback[2]?.title}
                        </p>
                    </div>
                </div>
            </div>
        )
    );
};
