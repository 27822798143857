import React from 'react';
export const FinalRemarks = ({ data, version }) => {
    let finalData = data.replace(/\*\*/g, '');
    return (
        <div className="detailed-feedback-list break-stop">
            {version > 4 && (
                <div className="skills-div">
                    <span className="soft-skills-span">Final Remarks</span>
                </div>
            )}
            <div className="soft-skill-wrapperv2">
                {version < 5 && (
                    <>
                        <div className="font-style-header-soft-skill soft-skill-header-600">Final Remarks</div>
                        <div className="soft-skill-header-outer">
                            <div className="soft-skill-header-type-1 font-style-header-soft-skill">Final Remarks</div>
                        </div>
                    </>
                )}
                <div className="soft-skill-content">
                    <pre className="font-style-soft-skill" style={{ whiteSpace: 'pre-wrap' }}>
                        <code
                            dangerouslySetInnerHTML={{
                                __html: `${finalData.replace('**', '')}`,
                            }}
                        />
                    </pre>
                </div>
            </div>
        </div>
    );
};
