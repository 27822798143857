import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Editor from '@monaco-editor/react';
import SplitPane from 'split-pane-react';
import { createCodeSubmission } from '../../../../redux/action';
import { getCodeSubmissionOutput, getSubmitLoading } from '../../../../redux/reducer/interviewReducer';
import { ToastManager } from '../../../Toast/ToastManager';
import { encodeToBase64, getDecodedOutput } from '../../../../common/utils';
import Modal from '../../../commonComponent/Modal/Modal';
import { getAssetSrc } from '../../../../utils/helper';
import {
    ASSET_NAMES,
    CODE_EDITOR_LANGUAGES_MAP,
    codeLanguageIdMapping,
    getCodeLanguages,
} from '../../../../common/constants';
import 'split-pane-react/esm/themes/default.css';

const Loader = memo(() => {
    return (
        <div className="wrapper-4">
            <div className="loader"></div>
        </div>
    );
});

const CodeEditor = ({
    updateSocket = () => {},
    form,
    setForm = () => {},
    handleSubmit = () => {},
    hasAnythingChangedInCodeEditor,
    setHasAnythingChangedInCodeEditor = () => {},
    setIsSubmitModalOpen,
    isSubmitModalOpen,
    lastQuestion,
    isPlaying,
    question,
}) => {
    const languageList = getCodeLanguages();
    const dispatch = useDispatch();
    const editorRef = useRef(null);
    const submitLoading = useSelector(getSubmitLoading);
    const codeOutput = useSelector(getCodeSubmissionOutput);
    const [isLanguageChange, setIsLanguageChange] = useState(false);
    const [tempLanguage, setTempLanguage] = useState({
        label: '',
        value: '',
    });
    const [sizes, setSizes] = useState(['70%', '30%']);
    const [sizes2, setSizes2] = useState(['48%', '52%']);
    const java = [91, 62];
    const cpp = [52, 53, 54, 76];
    const c = [48, 49, 50, 75];
    const javascript = [63, 93];
    const python = [92, 71];

    useEffect(() => {
        const decodedOutput = getDecodedOutput(codeOutput);
        if (decodedOutput) {
            const codingForm = { ...form, output: decodedOutput, codeUpdated: true };
            setHasAnythingChangedInCodeEditor(true);
            setForm(codingForm);
            updateSocket('codeEditorForm', codingForm);
        }
    }, [codeOutput]);

    useEffect(() => {
        if (question) {
            setSizes(['70%', '30%']);
            setSizes2(['48%', '52%']);
        }
    }, [question]);

    const getCodeEditorText = (value) => {
        const lang = form?.preBuiltFunction?.find(
            (object) => CODE_EDITOR_LANGUAGES_MAP[value]?.toLowerCase() === object?.language?.toLowerCase()
        );
        if (lang?.code) return lang?.code;
        if (value === 62 || value === 91) {
            return `import java.util.*;
import java.util.stream.*;
import java.lang.*;
import java.io.*;

class Solution {
    public void solve() {
        // Write your code here
       
    }
}

public class Main {
    public static void main(String[] args) {
        Solution solution = new Solution();
        solution.solve();
    }
}`;
        }
        if (value === 83) {
            return `import Foundation

class Solution {
    func solve() {
        // Write your code here
    }
}

let solution = Solution()
solution.solve()`;
        }
        return '';
    };

    const handleDidMount = (editor, monaco) => {
        editorRef.current = editor;
        const envMode = process.env.REACT_APP_ENV;
        if (envMode === 'prod') {
            // Disable copy (Ctrl+C and Cmd+C)
            editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyC, () => {
                // No-op to disable copying
            });

            // Disable paste (Ctrl+V and Cmd+V)
            editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyV, () => {
                // No-op to disable pasting
            });

            // Disable context menu copy and paste
            editor.onContextMenu((e) => {
                e.event.preventDefault();
                e.event.stopPropagation();
            });
        }

        // Store editor reference
        editor?.focus();
    };

    const scrollToTop = () => {
        if (editorRef.current) {
            editorRef.current.setScrollTop(0);
        }
    };

    const handleLanguageChange = (e) => {
        if (form?.language) {
            setTempLanguage(e);
            setIsLanguageChange(true);
        } else {
            let value = parseInt(e?.value);
            let nDR = codeLanguageIdMapping[value];
            const codingForm = {
                ...form,
                programmingLanguage: e?.label,
                language: value,
                editorLang: nDR,
                prebuilt: getCodeEditorText(value),
                codeEditor: getCodeEditorText(value),
                output: '',
                input: '',
            };
            setHasAnythingChangedInCodeEditor(true);
            setForm(codingForm);
            updateSocket('codeEditorForm', codingForm);
            scrollToTop();
            editorRef.current?.focus();
        }
    };

    const handleCloseChangeLanguageModal = () => {
        setTempLanguage({
            label: '',
            value: '',
        });
        setIsLanguageChange(false);
    };

    const handleFinalLanguageChange = () => {
        let value = parseInt(tempLanguage?.value);
        let nDR = codeLanguageIdMapping[value];
        const codingForm = {
            ...form,
            programmingLanguage: tempLanguage?.label,
            language: value,
            editorLang: nDR,
            prebuilt: getCodeEditorText(value),
            codeEditor: getCodeEditorText(value),
            output: '',
            input: '',
        };
        setHasAnythingChangedInCodeEditor(true);
        setForm(codingForm);
        updateSocket('codeEditorForm', codingForm);
        scrollToTop();
        editorRef.current?.focus();
        handleCloseChangeLanguageModal();
    };

    const handleCodeEditor = (value) => {
        const codingForm = { ...form, codeEditor: value ?? '' };
        if (value) setHasAnythingChangedInCodeEditor(true);
        else setHasAnythingChangedInCodeEditor(false);
        setForm(codingForm);
        updateSocket('codeEditorForm', codingForm);
    };

    const handleRun = () => {
        const codingForm = {
            ...form,
            output: '',
        };
        setForm(codingForm);
        updateSocket('codeEditorForm', codingForm);
        if (form?.language) {
            if (form?.codeEditor && form?.codeEditor?.trim()) {
                const encoded = encodeToBase64(form?.codeEditor);
                const inputEncoded = encodeToBase64(form?.input);
                const dataSet = {
                    source_code: encoded,
                    language_id: form?.language,
                    stdin: inputEncoded,
                };
                dispatch(
                    createCodeSubmission({
                        data: dataSet,
                        loader: true,
                        callback: (data) => {
                            if (data?.status?.description !== 'Accepted') {
                                ToastManager.addToast({
                                    title: data?.status?.description || '400 Bad Request',
                                });
                            } else {
                                ToastManager.addToast({
                                    title: 'Code run successfully',
                                    autoClose: 5000,
                                });
                            }
                        },
                    })
                );
            } else {
                ToastManager.addToast({
                    title: 'Please enter code to continue',
                    autoClose: 5000,
                });
            }
        } else {
            ToastManager.addToast({
                title: 'Please select language to continue',
                autoClose: 5000,
            });
        }
    };

    const handleInputChange = (e) => {
        setHasAnythingChangedInCodeEditor(true);
        setForm((prevState) => ({
            ...prevState,
            input: e.target.value,
        }));
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: '#191924',
            '&:hover': {
                backgroundColor: '#3D3D57',
            },
            color: '#ffffff',
            cursor: 'pointer',
        }),
        control: (provided) => ({
            ...provided,
            color: '#FFFFFF',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#ffffff',
        }),
        input: (provided) => ({
            ...provided,
            color: '#FFFFFF',
        }),
    };

    const getReadOnlyRanges = (form) => {
        const lines = getCodeEditorText(form?.language)?.split('\n') ?? [];
        if (lines.length === 1 && !lines[0]) return [];
        const ranges = [];
        lines.forEach((line, index) => {
            if (line.includes('{ Driver Code Starts')) {
                ranges.push({
                    startLineNumber: index + 1,
                });
            } else if (line.includes('} Driver Code Ends')) {
                ranges[ranges.length - 1].endLineNumber = index + 1;
            }
        });
        return ranges;
    };

    const handleCodeEditorChange = (val, s) => {
        const readOnlyRanges = getReadOnlyRanges(form);
        const changedRange = s.changes[0].range;
        const isInReadOnlyRange = readOnlyRanges.some(
            (range) =>
                changedRange.startLineNumber >= range.startLineNumber &&
                changedRange.startLineNumber <= range.endLineNumber
        );
        if (isInReadOnlyRange) {
            if (editorRef.current?.getValue() !== form?.codeEditor) {
                const currentSelection = editorRef.current.getSelection();
                editorRef.current.setValue(form?.codeEditor);
                editorRef.current.setSelection(currentSelection);
            }
        } else handleCodeEditor(val);
    };

    const handleSubmitCode = () => {
        if (form?.language) {
            if (form?.codeEditor) {
                setIsSubmitModalOpen(true);
            } else {
                ToastManager.addToast({
                    title: 'Please enter code to continue',
                    autoClose: 5000,
                });
            }
        } else {
            ToastManager.addToast({
                title: 'Please select language to continue',
                autoClose: 5000,
            });
        }
    };

    const handleCloseSubmitModal = () => setIsSubmitModalOpen(false);

    const SubmitCodeModalContent = () => {
        return (
            <div>
                <span>Do you want to submit this code? </span>
                <br />
                <br />
                {!lastQuestion ? (
                    <span>
                        You will be moved to next question once submitting and will not be able to make further changes
                        to your code
                    </span>
                ) : (
                    <span>You will not be able to make further changes to your code</span>
                )}
            </div>
        );
    };

    const LanguageChangeModalContent = () => {
        return (
            <div>
                <span>
                    Switching the coding language will erase all your existing code. This action cannot be undone.
                </span>
                <br />
                <br />
                <span>Are you sure you want to proceed?</span>
            </div>
        );
    };

    const handleCopy = (event) => {
        const envMode = process.env.REACT_APP_ENV;
        if (envMode === 'prod') {
            event.preventDefault();
        }
    };

    const handlePaste = (event) => {
        const envMode = process.env.REACT_APP_ENV;
        if (envMode === 'prod') {
            event.preventDefault();
        }
    };

    const handleCut = (event) => {
        const envMode = process.env.REACT_APP_ENV;
        if (envMode === 'prod') {
            event.preventDefault();
        }
    };

    const getLanguageOptions = () => {
        return languageList.filter((lang) => {
            if (form?.neededLanguage === 'SQL') return lang?.value === 82;
            else if (form?.neededLanguage === 'Javascript') return javascript.includes(lang?.value);
            else if (form?.neededLanguage === 'Java') return java.includes(lang?.value);
            else if (form?.neededLanguage === 'C++') return cpp.includes(lang?.value);
            else if (form?.neededLanguage === 'C') return c.includes(lang?.value);
            else if (form?.neededLanguage === 'Python') return python.includes(lang?.value);
            else if (form?.neededLanguage === 'C#') return lang?.value === 51;
            else if (form?.neededLanguage === 'Swift') return lang?.value === 83;
            else if (form?.neededLanguage === 'PHP') return lang?.value === 68;
            else return lang?.value !== 82;
        });
    };

    return (
        <div className="code-editor-container">
            {submitLoading && <Loader />}
            <Modal
                type={1}
                isOpen={isLanguageChange}
                onClose={handleCloseChangeLanguageModal}
                headerText="Change Programming Language?"
                buttonText="Confirm"
                handleClick={() => handleFinalLanguageChange()}
                primaryBtnText="Cancel"
                handlePrimaryBtnClick={handleCloseChangeLanguageModal}
                Content={LanguageChangeModalContent}
            />
            <Modal
                type={1}
                isOpen={isSubmitModalOpen}
                onClose={handleCloseSubmitModal}
                headerText="Submit Code"
                buttonText="Submit"
                handleClick={() => handleSubmit(handleCloseSubmitModal)}
                primaryBtnText="Cancel"
                handlePrimaryBtnClick={handleCloseSubmitModal}
                Content={SubmitCodeModalContent}
            />
            <div className="code-editor-header">
                <div className="d-flex align-items-center">
                    <div className="language-text">Language</div>
                    <Select
                        name="language"
                        className="select-language-container"
                        value={{
                            label: form?.language
                                ? languageList.filter((itm) => itm.value === form?.language)[0]?.label
                                : 'Select Language',
                            value: form?.language ? form?.language : '',
                        }}
                        menuPlacement="auto"
                        onChange={handleLanguageChange}
                        options={getLanguageOptions()}
                        styles={customStyles}
                    />
                </div>
                <div className="d-flex align-items-center">
                    <button className="run-code-btn" onClick={handleRun} disabled={isPlaying}>
                        <img src={getAssetSrc(ASSET_NAMES.RUN_CODE)} alt="" />
                        <span>Run Code</span>
                    </button>
                    <button
                        className="submit-code-btn"
                        onClick={handleSubmitCode}
                        disabled={!hasAnythingChangedInCodeEditor && isPlaying}
                    >
                        Submit Code
                    </button>
                </div>
            </div>
            <div className="editor-container">
                <SplitPane split="vertical" sizes={sizes} onChange={setSizes}>
                    <Editor
                        height="100%"
                        width="100%"
                        language={form?.editorLang}
                        options={{
                            minimap: {
                                enabled: false,
                            },
                            readOnly: false,
                            overflow: 'auto',
                            wordWrap: 'on',
                            contextmenu: false,
                        }}
                        className="code-editor"
                        theme="vs-dark"
                        value={form.codeEditor}
                        onChange={handleCodeEditorChange}
                        onMount={handleDidMount}
                    />
                    <div className="input-output-container">
                        {form?.neededLanguage === 'SQL' ? (
                            <div className="output-container sql">
                                <div className={form.output ? '' : 'output-appears'}>
                                    <pre className="code-content-pre-code-editor">
                                        <code
                                            dangerouslySetInnerHTML={{
                                                __html: `${!form.output ? 'Output appears here' : form.output.replace(/\|/g, ' | ')}`,
                                            }}
                                        />
                                    </pre>
                                </div>
                            </div>
                        ) : (
                            <SplitPane split="horizontal" sizes={sizes2} onChange={setSizes2}>
                                <textarea
                                    value={form.input}
                                    onCopy={handleCopy}
                                    onPaste={handlePaste}
                                    onCut={handleCut}
                                    onChange={handleInputChange}
                                    placeholder="Enter Input"
                                    className="input-container"
                                />
                                <div className="output-container">
                                    <div className={form.output ? '' : 'output-appears'}>
                                        {!form.output ? 'Output appears here' : form.output}
                                    </div>
                                </div>
                            </SplitPane>
                        )}
                    </div>
                </SplitPane>
            </div>
        </div>
    );
};

export default CodeEditor;
