import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Flex } from '@100mslive/roomkit-react';
import { FOFPage } from '../../components/FOFPage';
import { LinkExpired } from '../../components/LinkExpired';
import MobileDeviceUnsupported from '../../components/mobile/MobileDeviceUnsupported';
import { getInterviewToken, postCandidate } from '../../../../redux/action';
import FullPageProgress from '../../../FullPageProgress';
import validatePostSingleCandidate from '../../validation/post-candidate';
import EarlyJoin from '../EarlyJoin';
import Avatar from './Avatar';
import CandidateDetails from './CandidateDetails';
import InterviewStartHeader from './InterviewStartHeader';
import { isMobileOrTabletDevice } from '../../../../utils/helper';
const InterviewSignUp = () => {
    const video1 = 'https://storage.googleapis.com/incruiter-recording-prod/files/incbot/candidateDetails.webm';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { positionId, questionId, linkId } = useParams();
    const loading = useSelector((state) => state.Interview.loading);
    const interviewToken = useSelector((state) => state.Interview.interviewToken);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [linkNotStarted, setLinkNotStarted] = useState(false);
    const [remainingTime, setRemainingTime] = useState('');
    const GENERAL_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

    useEffect(() => {
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };

        // Attach the event listener to update dimensions when the window is resized
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures the effect runs only once after initial render
    useEffect(() => {
        dispatch(getInterviewToken({ positionId, questionId, linkId }));
    }, [dispatch, positionId, questionId, linkId]);
    const [form, setForm] = useState({
        position: positionId,
        question: questionId,
        candidate: {
            name: '',
            email: '',
            phone: '',
        },
        resume: '',
        user: '',
        createdBy: '',
        linkExpiresIn: 5,
        linkExpiryType: 'day-wise',
        startAt: '',
    });
    useEffect(() => {
        setForm((prevState) => ({
            ...prevState,
            user: interviewToken?.user,
            createdBy: interviewToken?.createdBy,
            linkExpiresIn: interviewToken?.linkExpiresIn,
            startAt: interviewToken?.startAt,
            linkExpiryType: interviewToken?.linkExpiryType,
        }));
    }, [interviewToken]);

    useEffect(() => {
        if (interviewToken?.startAt) {
            const startDate = new Date(interviewToken.startAt);
            const currentDate = new Date();
            if (startDate > currentDate) {
                setLinkNotStarted(true);
                const remainingTime = moment(startDate).format(GENERAL_DATE_FORMAT);
                setRemainingTime(remainingTime);
            } else {
                setLinkNotStarted(false);
                setRemainingTime('');
            }
        }
    }, [interviewToken?.startAt]);
    const handleSubmit = (e) => {
        const { isValid } = validatePostSingleCandidate(form);
        const currentDate = new Date();
        const futureDate = new Date(currentDate);
        futureDate.setDate(futureDate.getDate() + 5);
        const mongodbDate = futureDate.toISOString();
        if (isValid) {
            dispatch(
                postCandidate({
                    form: {
                        ...form,
                        expiredAt: interviewToken?.expiredAt ? interviewToken?.expiredAt : mongodbDate,
                    },
                    callback: (data) => {
                        navigate(`/interview-room/candidate/${data?.roomId}/send`, {
                            state: {
                                positionId: positionId,
                                questionId: questionId,
                                linkId: linkId,
                            },
                        });
                    },
                })
            );
        }
    };

    if (isMobileOrTabletDevice() && interviewToken?.isCodingQuestionAvailable) return <MobileDeviceUnsupported />;

    return loading ? (
        <FullPageProgress />
    ) : linkNotStarted ? (
        <EarlyJoin remainingTime={remainingTime} />
    ) : interviewToken ? (
        interviewToken?.roomExpired || interviewToken?.positionClosed || interviewToken?.alreadyAttempted ? (
            <Flex css={{ size: '100%', background: '#2e2e41' }} direction="column">
                <LinkExpired
                    message={
                        interviewToken?.positionClosed
                            ? 'Position has been closed.'
                            : interviewToken?.alreadyAttempted
                              ? 'You have already attempted for the interview.'
                              : 'The interview link has expired.'
                    }
                />
            </Flex>
        ) : interviewToken?.status ? (
            <div className="interview-container">
                <InterviewStartHeader />
                <div className={`${screenHeight > 550 ? 'instruction-container-main' : ''}`}>
                    <div className={`instruction-container ${screenHeight > 550 ? 'w-85vw' : ''}`}>
                        <CandidateDetails form={form} setForm={setForm} handleSubmit={handleSubmit} />
                        {!isMobileOrTabletDevice() && <Avatar video={video1} />}
                    </div>
                </div>
            </div>
        ) : (
            <Flex css={{ size: '100%', background: '#2e2e41', position: 'relative' }} direction="column">
                <FOFPage />
            </Flex>
        )
    ) : (
        <FullPageProgress />
    );
};

export default InterviewSignUp;
