import React, { useEffect, useRef, useState } from 'react';
import useSound from 'use-sound';
import { selectPeers, useHMSStore } from '@100mslive/react-sdk';
import { Box, Flex } from '@100mslive/roomkit-react';
import beep from '../../../assets/audio/beep.mp3';
import { GridCenterView } from '../../gridView';
import PracticeQuestion from './PracticeQuestion';
import PracticeQuestionInfo from './PracticeQuestionInfo';
const PracticeDesktop = React.memo(
    ({
        form,
        questionInfo,
        isPlaying,
        prepareTime,
        setPrepareTime,
        answerTime,
        setAnswerTime,
        handleEndCall,
        interviewStarted,
        onlineStatus,
        setAnswerDuration,
        answerDuration,
        handleSubmit,
        displayInput,
        setDisplayInput,
        isUpdated,
        playAudio,
        skipTimer,
        waitingPrepTime,
        waitingAnswerTime,
        setWaitingAnswerTime,
        timerEnd,
        token,
    }) => {
        const peers = useHMSStore(selectPeers);
        const candidatePeer = peers.filter((peer) => peer.roleName === 'candidate');
        const [playBeep] = useSound(beep);
        const videoRef = useRef(null);
        const [questionEnded, setQuestionEnded] = useState(false);
        const [isNextClick, setIsNextClick] = useState(false);
        const [isButtonDisabled, setIsButtonDisabled] = useState(false);
        useEffect(() => {
            if (displayInput?.audio) {
                playAudio();
            }
        }, [displayInput?.audio]);

        useEffect(() => {
            const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
            if (!waitingPrepTime && !isPlaying && questionInfo?.questionNumber > totalQuestion && answerTime === 0)
                handleEndCall(false, 'Auto Interview Submitted');
        }, [isPlaying, waitingPrepTime]);

        useEffect(() => {
            if (isNextClick) setIsNextClick(false);
        }, [isNextClick]);

        useEffect(() => {
            if (displayInput?.playBeepSound) {
                const value = {
                    ...displayInput,
                    playBeepSound: false,
                };
                setDisplayInput(value);
            }
        }, [displayInput?.playBeepSound]);
        useEffect(() => {
            const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
            if (
                !waitingPrepTime &&
                !isPlaying &&
                interviewStarted &&
                questionInfo?.questionNumber <= totalQuestion &&
                answerTime > 0
            ) {
                let prepareTimer;
                const prepTimerFunc = async () => {
                    if (prepareTime > 0) {
                        setPrepareTime(prepareTime - 1);
                    } else {
                        if (!isNextClick) {
                            const dValue = {
                                ...displayInput,
                                playBeepSound: true,
                            };
                            setDisplayInput(dValue);
                            playBeep();
                        }
                        clearInterval(prepareTimer);
                    }
                };
                prepareTimer = setInterval(() => {
                    !onlineStatus ? clearInterval(prepareTimer) : prepTimerFunc();
                }, 1000);
                return () => {
                    clearInterval(prepareTimer);
                };
            }
        }, [waitingPrepTime, isPlaying, prepareTime, onlineStatus]);

        useEffect(() => {
            const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
            if (
                !waitingPrepTime &&
                !isPlaying &&
                prepareTime === 0 &&
                interviewStarted &&
                questionInfo?.questionNumber <= totalQuestion
            ) {
                let answerTimer;
                if (!waitingAnswerTime) setWaitingAnswerTime(true);
                const ansTimerFunc = () => {
                    if (answerTime > 0) {
                        setAnswerTime(answerTime - 1);
                        setAnswerDuration(answerDuration + 1);
                    } else {
                        setWaitingAnswerTime(false);
                        setQuestionEnded(true);
                        clearInterval(answerTimer);
                    }
                };
                answerTimer = setInterval(async () => {
                    !onlineStatus ? clearInterval(answerTimer) : ansTimerFunc();
                }, 1000);
                return () => {
                    clearInterval(answerTimer);
                };
            }
        }, [waitingPrepTime, prepareTime, answerTime, form, questionInfo, onlineStatus, isPlaying]);

        useEffect(() => {
            const handleSubmission = async () => {
                if (!isPlaying && questionEnded && !waitingAnswerTime && answerTime === 0) await handleSubmit('Mobile');
            };
            handleSubmission();
        }, [questionEnded, isPlaying, answerTime, waitingAnswerTime]);

        useEffect(() => {
            const totalQuestion = form?.totalQuestion ? parseInt(form?.totalQuestion) : 0;
            if (totalQuestion < questionInfo?.questionNumber) {
                timerEnd();
            }
        }, [questionInfo?.questionNumber, form?.totalQuestion]);

        const handleNextQuestionClick = async () => {
            if (!isButtonDisabled) {
                setIsNextClick(true);
                timerEnd();
                setIsButtonDisabled(true);
                await handleSubmit('Mobile');
                setIsButtonDisabled(false);
            }
        };
        return (
            <Box
                css={{
                    w: '100%',
                    flex: '1 1 0',
                    minHeight: 0,
                    paddingBottom: 'env(safe-area-inset-bottom)',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                }}
                id="conferencing"
                data-testid="conferencing"
            >
                <PracticeQuestionInfo form={form} questionInfo={questionInfo} handleEndCall={handleEndCall} />
                <Flex
                    css={{
                        width: '100%',
                        overflow: 'hidden',
                        height: '80vh',
                        flexGrow: '1',
                    }}
                    direction={{ '@initial': 'row', '@md': 'column' }}
                    ref={videoRef}
                    className="video_question_container"
                >
                    <Flex
                        css={{
                            size: '100%',
                            flexDirection: 'column',
                            position: 'relative',
                        }}
                        className="video_container"
                        direction={{
                            '@initial': 'column',
                            '@md': 'column',
                        }}
                    >
                        <GridCenterView
                            peers={candidatePeer}
                            maxTileCount={1}
                            // form={form}
                            practiceStatus={true}
                        />
                    </Flex>
                    {isUpdated && (
                        <PracticeQuestion
                            questionInfo={questionInfo}
                            isSpeaking={isPlaying}
                            totalQuestion={form?.totalQuestion ? parseInt(form?.totalQuestion) : 0}
                            isSkipEnabled={prepareTime < questionInfo?.prepareTime && prepareTime > 0}
                            prepareTime={prepareTime}
                            answerTime={answerTime}
                            skipTimer={skipTimer}
                            handleEndCall={handleEndCall}
                            isButtonDisabled={isButtonDisabled}
                            handleNextQuestionClick={handleNextQuestionClick}
                            isNextButtonEnabled={
                                (prepareTime < questionInfo?.prepareTime && prepareTime > 0) ||
                                (answerTime < questionInfo?.timeToAnswer && answerTime > 0)
                            }
                            question={
                                isPlaying
                                    ? displayInput?.message
                                    : (questionInfo?.prepareTime !== prepareTime ||
                                            questionInfo?.timeToAnswer !== answerTime) &&
                                        answerTime > 0
                                      ? displayInput?.displayText
                                      : ''
                            }
                        />
                    )}
                </Flex>
            </Box>
        );
    }
);

export default PracticeDesktop;
