import React from 'react';
import { getConfidenceRating, getSkill, getStatusV } from './components/Helpers';
import high from '../../assets/images/high.svg';
import low from '../../assets/images/low.svg';
import medium from '../../assets/images/medium.svg';
export const InterviewAssessment = ({ data, version }) => {
    return version > 4 ? (
        <div className="skill-rating-div d-flex flex-column">
            <div className="mandatory-skills-graph-wrapper">
                {data &&
                    data.length > 0 &&
                    data.map((item, key) => (
                        <div className="individual-graph-wrapper break-stop" key={key}>
                            {item?.title !== 'Confidence' ? (
                                <>
                                    <img className="graph-img" src={getSkill(item?.value)} alt="Rating" />
                                    <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                        {item.title
                                            ? item.title.length > 21
                                                ? item.title.substring(0, 21) + '...'
                                                : item.title
                                            : ''}
                                    </p>
                                    <p style={{ margin: '0px', 'line-height': '0px' }}>
                                        <span className={`graph-descp-comment ${getStatusV(item.value)?.color}`}>
                                            {getStatusV(item.value)?.title.substring(0, 21)}
                                        </span>
                                    </p>
                                </>
                            ) : (
                                <>
                                    <img className="graph-img" src={getConfidenceRating(item?.value)} alt="Rating" />
                                    <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                        {item.title
                                            ? item.title.length > 21
                                                ? item.title.substring(0, 21) + '...'
                                                : item.title
                                            : ''}
                                    </p>
                                    <p style={{ margin: '0px', 'line-height': '0px' }}>
                                        <span
                                            className={`graph-descp-comment ${
                                                item?.value > 6
                                                    ? 'status-green'
                                                    : item?.value > 4
                                                      ? 'status-yellow'
                                                      : 'status-red'
                                            }`}
                                        >
                                            {item?.value > 8
                                                ? 'High'
                                                : item?.value > 6
                                                  ? 'Good'
                                                  : item?.value > 4
                                                    ? 'Moderate'
                                                    : 'Low'}
                                        </span>
                                    </p>
                                </>
                            )}
                        </div>
                    ))}
                <div className="bar-chart-title">Soft skills Assessment</div>
            </div>
        </div>
    ) : (
        <div className="skill-rating-div">
            <div className="skills-div">
                <span className="soft-skills-span">Interview Assessment</span>
            </div>
            <div className="mandatory-skills-graph-wrapper">
                {data &&
                    data.length > 0 &&
                    data.map((item, key) => (
                        <div className="individual-graph-wrapper break-stop" key={key}>
                            {item?.title !== 'Confidence' ? (
                                <>
                                    <img className="graph-img" src={getSkill(item?.value)} alt="Rating" />
                                    <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                        {item.title
                                            ? item.title.length > 21
                                                ? item.title.substring(0, 21) + '...'
                                                : item.title
                                            : ''}
                                    </p>
                                    <p style={{ margin: '0px', 'line-height': '0px' }}>
                                        <span className={`graph-descp-comment ${getStatusV(item.value)?.color}`}>
                                            {getStatusV(item.value)?.title.substring(0, 21)}
                                        </span>
                                    </p>
                                </>
                            ) : version > 2 ? (
                                <>
                                    <img className="graph-img" src={getConfidenceRating(item?.value)} alt="Rating" />
                                    <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                        {item.title
                                            ? item.title.length > 21
                                                ? item.title.substring(0, 21) + '...'
                                                : item.title
                                            : ''}
                                    </p>
                                    <p style={{ margin: '0px', 'line-height': '0px' }}>
                                        <span
                                            className={`graph-descp-comment ${
                                                item?.value > 6
                                                    ? 'status-green'
                                                    : item?.value > 4
                                                      ? 'status-yellow'
                                                      : 'status-red'
                                            }`}
                                        >
                                            {item?.value > 8
                                                ? 'High'
                                                : item?.value > 6
                                                  ? 'Good'
                                                  : item?.value > 4
                                                    ? 'Moderate'
                                                    : 'Low'}
                                        </span>
                                    </p>
                                </>
                            ) : (
                                <>
                                    <img
                                        className="graph-img"
                                        src={item?.value > 8 ? high : item?.value > 4 ? medium : low}
                                        alt="Rating"
                                    />
                                    <p className="graph-title" style={{ margin: '0px' }} title={item?.title}>
                                        {item.title
                                            ? item.title.length > 21
                                                ? item.title.substring(0, 21) + '...'
                                                : item.title
                                            : ''}
                                    </p>
                                    <p style={{ margin: '0px', 'line-height': '0px' }}>
                                        <span
                                            className={`graph-descp-comment ${
                                                item?.value > 8
                                                    ? 'status-green'
                                                    : item?.value > 4
                                                      ? 'status-yellow'
                                                      : 'status-red'
                                            }`}
                                        >
                                            {item?.value > 8 ? 'High' : item?.value > 4 ? 'Medium' : 'Low'}
                                        </span>
                                    </p>
                                </>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
};
