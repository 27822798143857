import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HMSRoomProvider, selectIsConnectedToRoom, useHMSActions, useHMSStore } from '@100mslive/react-sdk';
import { Box, globalStyles, HMSThemeProvider } from '@100mslive/roomkit-react';
import { AppData } from './components/AppData/AppData.jsx';
import Conference from './components/conference';
import { ErrorBoundary } from './components/ErrorBoundary';
import FeedbackRoom from './components/feedbackRoom';
import FullPageProgress from './components/FullPageProgress';
import { Init } from './components/init/Init';
import { KeyboardHandler } from './components/Input/KeyboardInputManager';
import { Notifications } from './components/Notifications';
import InterviewSignUp from './components/Pages/components/PreviewDesktop/InterviewSignUp.jsx';
import InterviewStart from './components/Pages/components/PreviewDesktop/InterviewStart.jsx';
import Other from './components/Pages/Other.jsx';
import PostLeave from './components/PostLeave';
import PracticeRoom from './components/PracticeRoom';
import PreviewScreen from './components/PreviewScreen';
import RecordingRoom from './components/recordingRoom';
import { ToastContainer } from './components/Toast/ToastContainer';
import store from './redux/store';
import { palette } from './theme.js';
import { Confetti } from './plugins/confetti';
import { RemoteStopScreenshare } from './plugins/RemoteStopScreenshare';
import { shadeColor } from './common/utils';
import { FeatureFlags } from './services/FeatureFlags';
import { generateUrl } from './utils/helper.jsx';
import './assets/fonts/stylesheet.css';
import './assets/css/portal.css';
const defaultTokenEndpoint = generateUrl('token_generation');
const envPolicyConfig = JSON.parse('{}');

let appName;
if (window.location.host.includes('localhost')) {
    appName = 'localhost';
} else {
    appName = window.location.host.split('.')[0];
}

document.title = 'InCruiter - One Way Interview Platform' || `${appName}'s ${document.title}`;

// TODO: remove now that there are options to change to portrait
const getAspectRatio = ({ width, height }) => {
    const host = process.env.REACT_APP_HOST_NAME || window.location.hostname;
    const portraitDomains = (process.env.REACT_APP_PORTRAIT_MODE_DOMAINS || '').split(',');
    if (portraitDomains.includes(host) && width > height) {
        return { width: height, height: width };
    }
    return { width, height };
};

export function EdtechComponent({
    tokenEndpoint = defaultTokenEndpoint,
    themeConfig: {
        aspectRatio = '1-1',
        color = '#2F80FF',
        theme = 'dark',
        logo = '',
        headerPresent = 'false',
        metadata = '',
        recordingUrl = `${generateUrl('meeting')}/preview/beam`,
    },
    policyConfig = envPolicyConfig,
    getDetails = () => {},
    authTokenByRoomCodeEndpoint = '',
}) {
    const { 0: width, 1: height } = aspectRatio.split('-').map((el) => parseInt(el));
    globalStyles();
    return (
        <Provider store={store}>
            <ErrorBoundary>
                <HMSThemeProvider
                    themeType={theme}
                    aspectRatio={getAspectRatio({ width, height })}
                    theme={{
                        colors: {
                            ...palette[theme],
                            primary_default: color,
                            primary_dim: shadeColor(color, -30),
                            primary_bright: shadeColor(color, 30),
                            primary_disabled: shadeColor(color, 10),
                        },
                    }}
                >
                    <HMSRoomProvider isHMSStatsOn={FeatureFlags.enableStatsForNerds} leaveOnUnload={false}>
                        <AppData
                            appDetails={metadata}
                            policyConfig={policyConfig}
                            logo={logo}
                            recordingUrl={recordingUrl}
                            tokenEndpoint={tokenEndpoint}
                        />
                        <Init />
                        <Box
                            css={{
                                bg: '#191924',
                                w: '100%',
                                lineHeight: '1.5',
                                '-webkit-text-size-adjust': '100%',
                                ...(headerPresent === 'true' ? { flex: '1 1 0', minHeight: 0 } : { h: '100%' }),
                            }}
                        >
                            <AppRoutes
                                getDetails={getDetails}
                                authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint}
                            />
                        </Box>
                    </HMSRoomProvider>
                </HMSThemeProvider>
            </ErrorBoundary>
        </Provider>
    );
}

const RouteList = ({ authTokenByRoomCodeEndpoint }) => {
    return (
        <Routes>
            <Route path="recording/room/:interviewId/:roomId" element={<RecordingRoom />} />
            <Route path="feedback/room/:interviewId/:roomId" element={<FeedbackRoom />} />
            <Route
                path="practice/room/:role/:roomId"
                element={
                    <Suspense fallback={<FullPageProgress />}>
                        <PracticeRoom />
                    </Suspense>
                }
            />
            <Route
                path="preview/:role/:roomId"
                element={
                    <Suspense fallback={<FullPageProgress />}>
                        <PreviewScreen authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint} />
                    </Suspense>
                }
            />
            <Route
                path="meeting/:role/:roomId"
                element={
                    <Suspense fallback={<FullPageProgress />}>
                        <Conference />
                    </Suspense>
                }
            />
            <Route path="interview-room">
                <Route
                    path=":role/:roomId"
                    element={
                        <Suspense fallback={<FullPageProgress />}>
                            <InterviewStart />
                        </Suspense>
                    }
                />
                <Route
                    path=":role/:roomId/send"
                    element={
                        <Suspense fallback={<FullPageProgress />}>
                            <InterviewStart />
                        </Suspense>
                    }
                />
            </Route>
            <Route path="leave/:role/:roomId" element={<PostLeave />} />
            <Route path="interview/:positionId/:questionId/:linkId" element={<InterviewSignUp />} />
            <Route path="*" element={<Other page="fofPage" />} />
        </Routes>
    );
};

const BackSwipe = () => {
    const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
    const hmsActions = useHMSActions();
    useEffect(() => {
        const onRouteLeave = async () => {
            if (isConnectedToRoom) {
                await hmsActions.leave();
            }
        };
        window.addEventListener('popstate', onRouteLeave);
        return () => {
            window.removeEventListener('popstate', onRouteLeave);
        };
    }, [hmsActions, isConnectedToRoom]);
    return null;
};

function AppRoutes({ getDetails, authTokenByRoomCodeEndpoint }) {
    return (
        <Router>
            <ToastContainer />
            <Notifications />
            <BackSwipe />
            <Confetti />
            <RemoteStopScreenshare />
            <KeyboardHandler />
            <Routes>
                <Route path="/*" element={<RouteList authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint} />} />
            </Routes>
        </Router>
    );
}

export default function App() {
    return (
        <EdtechComponent
            themeConfig={{
                aspectRatio: '1-1',
                theme: 'dark',
                color: '#2F80FF',
                logo: '',
                headerPresent: process.env.REACT_APP_HEADER_PRESENT,
                metadata: '{}', // A stringified object in env
            }}
        />
    );
}
