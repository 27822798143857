import React, { memo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const TotalScoreBar = memo(({ percentage, totalPer, header, data }) => {
    const colors = header
        ? [`${percentage > 6 ? '#61BE52' : percentage > 4 ? '#fc8c43' : '#f70910'}`, '#E6E9F8']
        : ['#133F7D', '#E6E9F8'];
    const totalPercentage = totalPer;
    const dataSet = {
        datasets: [
            {
                data: [percentage, totalPercentage - percentage],
                backgroundColor: colors,
                borderWidth: 0,
            },
        ],
    };

    const options = {
        maintainAspectRatio: true,
        cutout: '77%',
        events: [],
        plugins: {
            datalabels: {
                display: false, // Hide data labels
            },
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    };

    return (
        <div className="position-relative">
            <Doughnut id={header ? 'PercentageChart' : 'DataMatrixChart'} data={dataSet} options={options} />
            {header ? (
                <div className="resumePercentage large position-absolute" style={{ color: 'white' }}>
                    <span className="position-relative">
                        {percentage}
                        <div className="position-absolute total-per">{`/${totalPer}`}</div>
                    </span>
                </div>
            ) : (
                <div className="resumePercentage small position-absolute" style={{ color: '#133F7D' }}>
                    <span>{`${data ? `${data}` : header ? `${percentage}/${totalPercentage}` : `${percentage}`}`}</span>
                </div>
            )}
        </div>
    );
});

export default TotalScoreBar;
