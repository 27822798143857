import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, styled, Text } from '@100mslive/roomkit-react';
import logo from '../../../images/incruiter-logo.svg';
export const LinkExpired = ({ message = 'The interview link has expired.' }) => {
    const whiteLabelLogos = useSelector((state) => state.Interview.whiteLabelLogos);
    const fetchingWhiteLabelStatus = useSelector((state) => state.Interview.fetchingWhiteLabelStatus);
    return (
        <Flex direction="column" css={{ height: '100vh', width: '100vw' }}>
            <Flex
                justify="center"
                direction="column"
                align="center"
                css={{ bg: '#191924', flex: '1 1 0', position: 'relative' }}
            >
                {!fetchingWhiteLabelStatus && (
                    <LogoImg src={whiteLabelLogos?.whiteLogo || logo} alt="InCruiter Logo" width={265} height={109} />
                )}
                <br />
                <Text
                    variant="body1"
                    css={{
                        color: '#ffffff',
                        fontWeight: '$regular',
                        'font-size': '30px',
                        'line-height': '41px',
                        textAlign: 'center',
                        '@sm': { 'font-size': '24px', 'line-height': '30px' },
                    }}
                >
                    {message}
                </Text>
            </Flex>
        </Flex>
    );
};

const LogoImg = styled('img', {
    p: '$2',
    objectFit: 'contain',
});
