import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Button } from '@100mslive/roomkit-react';
import faceDetected from '../../../../assets/images/account_circle_green.svg';
import faceNotDetected from '../../../../assets/images/account_circle_red.svg';
import faceDetection from '../../../../assets/images/faceDetection.svg';
import practiceActiveVector from '../../../../assets/images/practice-active-vector.svg';
import practiceDisabledVector from '../../../../assets/images/practice-disabled-vector.svg';
import {
    createAndUpdateScreenshots,
    fetchScreenShotImage,
    fullFaceDetectionNew,
    practiceQuestion,
    practiceQuestionUpdate,
} from '../../../../redux/action';
import { ToastManager } from '../../../Toast/ToastManager';
import PreviewTile from './PreviewTile';
import Modal from '../../../commonComponent/Modal/Modal';
import { generateUrl, isMobileOrTabletDevice, isValidDataURI } from '../../../../utils/helper';
import { compressImage } from '../../utils/utility';
import { AUDIO_NOT_DETECTED } from '../../../../common/constants';
import { FACE_DETECTED } from '../../utils/constants';

const FaceNotDetectedContent = () => {
    return (
        <div className="face-detection-modal-content d-center gap-10 mb-3">
            <img src={faceDetection} className="face-detection-logo" />
            <div className="d-center flex-column">
                <div>
                    Uh-oh! It looks like your face is not fully visible or not detected by the camera. To help with
                    proper face detection, please try the following:
                </div>
                <div className="d-flex flex-column">
                    <div className="text-start">
                        <span className="f-bol">1. Sit upright</span> and ensure your face is centered in the camera
                        frame.
                    </div>
                    <div className="text-start">
                        <span className="f-bol">2. Adjust the lighting</span> so your face is well-lit and clearly
                        visible.
                    </div>
                    <div className="text-start">
                        <span className="f-bol">3. Move closer to the camera</span> if you're sitting too far away.
                    </div>
                    <div className="text-start">
                        <span className="f-bol">4. Check your camera angle</span> and ensure it is positioned at eye
                        level.
                    </div>
                </div>
            </div>
        </div>
    );
};

const AcknowledgeModal = memo(
    ({
        onJoin,
        name,
        form,
        token,
        previewError,
        value,
        positionTitle,
        companyName,
        videoContainerRef,
        speakingPermission,
        recorder,
        isButtonDisabled,
    }) => {
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const { roomId, role } = useParams();
        const basePath = generateUrl('interview_backend');
        const [isStarting, setIsStarting] = useState(false);
        const [practiceStatus, setPracticeStatus] = useState(false);
        const [hasFullFaceDetected, setHasFullFaceDetected] = useState(FACE_DETECTED.DEFAULT);
        const [faceDetectionError, setFaceDetectionError] = useState('');
        const [isProcessing, setIsProcessing] = useState(false);
        const [showModal, setShowModal] = useState(false);
        const canvasRef = useRef(null);
        const timerRef = useRef(null);
        const acknowledgeText = `Hello, my name is ${name}, and I am interviewing for the position of ${positionTitle} at ${companyName}.`;
        const sendCompressedFrame = async (base64Image) => {
            if (isProcessing || hasFullFaceDetected === FACE_DETECTED.DETECTED) return false;

            setIsProcessing(true);

            try {
                const result = await new Promise((resolve, reject) => {
                    dispatch(
                        fullFaceDetectionNew({
                            data: { image_base64: base64Image },
                            onSuccess: (hasFullFaceDetected, errorMessage) => {
                                setHasFullFaceDetected(
                                    hasFullFaceDetected ? FACE_DETECTED.DETECTED : FACE_DETECTED.NOT_DETECTED
                                );

                                if (base64Image && hasFullFaceDetected) {
                                    dispatch(
                                        fetchScreenShotImage({
                                            image: base64Image,
                                            interviewId: roomId,
                                            type: 10,
                                            callback: (image) => {
                                                if (isValidDataURI(image)) {
                                                    dispatch(
                                                        createAndUpdateScreenshots({
                                                            roomId: roomId,
                                                            screenshots: {
                                                                url: image,
                                                                type: 'main',
                                                                faceDetected: true,
                                                            },
                                                            mode: 'new',
                                                        })
                                                    );
                                                }
                                            },
                                        })
                                    );
                                }

                                if (errorMessage) {
                                    setFaceDetectionError(errorMessage);
                                } else {
                                    setFaceDetectionError('');
                                }

                                resolve(hasFullFaceDetected);
                            },
                            onFailure: (error) => {
                                console.error('Face detection error: ', error?.message);
                                reject(error);
                            },
                        })
                    );
                });

                return result;
            } catch (error) {
                console.error('Error in face detection:', error);
                return false;
            } finally {
                setIsProcessing(false);
                setIsStarting(false);
            }
        };

        const startModalTimer = () => {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                if (hasFullFaceDetected !== FACE_DETECTED.DETECTED) {
                    setShowModal(true);
                }
            }, 20000);
        };

        const closeModal = () => {
            setShowModal(false);
            startModalTimer();
        };

        useEffect(() => {
            let isMounted = true;

            const processFaceDetection = async () => {
                if (!isMounted || hasFullFaceDetected === FACE_DETECTED.DETECTED) return;

                try {
                    const compressedImage = await compressImage({
                        canvasRef,
                        onFailure: () => setHasFullFaceDetected(false),
                    });

                    const faceDetected = await sendCompressedFrame(compressedImage);

                    // If face is not detected and component is still mounted, schedule next detection
                    if (faceDetected) {
                        clearTimeout(timerRef.current);
                    } else if (isMounted && hasFullFaceDetected !== FACE_DETECTED.DETECTED) {
                        requestAnimationFrame(processFaceDetection);
                    }
                } catch (error) {
                    console.error('Error in face detection process:', error);
                    // Optionally, add a delay before retrying
                    if (isMounted && hasFullFaceDetected !== FACE_DETECTED.DETECTED) {
                        setTimeout(() => requestAnimationFrame(processFaceDetection), 1000);
                    }
                }
            };

            // Start the face detection process immediately
            processFaceDetection();
            startModalTimer();

            return () => {
                isMounted = false;
                clearTimeout(timerRef.current);
            };
        }, []);

        useEffect(() => {
            dispatch(
                practiceQuestion({
                    roomId: roomId,
                    callback: (status) => {
                        if (status) {
                            setPracticeStatus(true);
                        }
                    },
                })
            );
        }, [dispatch]);

        const handleText = () => {
            return new Promise((resolve) => {
                debounce(() => {
                    if (value || !speakingPermission) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }, 300)();
            });
        };

        const handlePracticeTest = () => {
            dispatch(
                practiceQuestionUpdate({
                    roomId: roomId,
                })
            );
            const meetingURL = `/practice/room/${role}/${roomId}`;
            navigate(meetingURL, { state: { status: true, form: form, token: token } });
        };

        const handleSubmit = async () => {
            setIsStarting(true);
            const hasTranscribed = await handleText();
            if (hasTranscribed) onJoin();
            else if (recorder) {
                recorder?.stopRecording(async () => {
                    try {
                        const blob = recorder?.getBlob();
                        console.log(blob);
                        if (blob?.size > 0) {
                            const formData = new FormData();
                            formData.append(
                                'video',
                                blob,
                                isMobileOrTabletDevice() ? 'recorded-audio.wav' : 'recorded-video.webm'
                            );
                            const response = await fetch(`${basePath}/fetchVoiceTranscribe`, {
                                method: 'POST',
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
                                    'Content-Type': 'multipart/form-data', // Set content type for form data
                                },
                                body: formData,
                            });
                            console.log('fetchVoiceTranscribe', response);
                            if (!response.ok) {
                                throw new Error('Fetch failed');
                            }
                            const data = await response.json();
                            if (data?.result?.transcript) {
                                onJoin();
                                return;
                            } else {
                                throw new Error('Transcript not found');
                            }
                        } else {
                            throw new Error('Blob is empty');
                        }
                    } catch (error) {
                        console.error('Error fetching voice:', error);
                    } finally {
                        setIsStarting(false);
                        recorder?.startRecording();
                        ToastManager.addToast({
                            title: AUDIO_NOT_DETECTED,
                        });
                    }
                });
            }
        };

        const practiceStatusFinal = !(isStarting || isButtonDisabled) && practiceStatus;

        return (
            <div className="interview-preview-page" ref={videoContainerRef}>
                <canvas ref={canvasRef} className="d-none" />
                <Modal
                    isOpen={showModal}
                    showCloseButton
                    onClose={closeModal}
                    headerText="Face Not Detected!"
                    Content={FaceNotDetectedContent}
                    buttonText="Okay"
                    handleClick={closeModal}
                    bodyStyle="p-0"
                    headerStyle="f-sem-bol f-24"
                />
                <div className="d-flex preview-page-layout">
                    <div className="w-50">
                        <div className="finish-title">Get Started</div>
                        <div className="finish-title-main pb-3 pt-2">Setup your audio and video before joining</div>
                        <PreviewTile name={name} error={previewError} auto />
                        <div className="d-flex w-100 align-items-center mt-1" style={{ color: 'white', fontSize: 14 }}>
                            {hasFullFaceDetected !== FACE_DETECTED.DEFAULT && (
                                <div
                                    className={`d-center me-3 ${hasFullFaceDetected === FACE_DETECTED.DETECTED ? 'green-color' : 'red-color'}`}
                                >
                                    <img
                                        className="me-1"
                                        src={
                                            hasFullFaceDetected === FACE_DETECTED.DETECTED
                                                ? faceDetected
                                                : faceNotDetected
                                        }
                                    />
                                    {hasFullFaceDetected}
                                </div>
                            )}
                            {hasFullFaceDetected === FACE_DETECTED.NOT_DETECTED && faceDetectionError && (
                                <div>{faceDetectionError}</div>
                            )}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-end p-4 pb-0 w-50">
                        <div className="finish-title-m pt-2">
                            Please read the line below to test your camera and microphone before starting the interview
                        </div>
                        <div className="dark-bg">
                            <p className="acknowledge_text-m my-auto">{acknowledgeText}</p>
                            <div className="px-2 py-4">
                                <div
                                    className={`${
                                        value
                                            ? 'acknowledge_text_transcript_new'
                                            : 'acknowledge_text_transcript_disable_new'
                                    }`}
                                >
                                    {value ? value : 'Please wait while speech is being transcribed'}
                                </div>
                            </div>
                            <div className="acknowledge_title_line my-2" />
                            <div className="d-flex justify-content-center">
                                <Button
                                    type="submit"
                                    disabled={
                                        isStarting || isButtonDisabled || hasFullFaceDetected !== FACE_DETECTED.DETECTED
                                    }
                                    onClick={handleSubmit}
                                    className={`mx-auto d-flex ${isStarting || isButtonDisabled || hasFullFaceDetected !== FACE_DETECTED.DETECTED ? 'start-transcript-check-btn-disabled' : 'start-transcript-check-btn'}`}
                                >
                                    Start Interview
                                </Button>
                            </div>
                        </div>
                        {practiceStatusFinal ? (
                            <div className="practice-test">
                                <span onClick={handlePracticeTest}>
                                    Practice Before Start
                                    <img src={practiceActiveVector} className="practice-vector" alt="Practice Test" />
                                </span>
                            </div>
                        ) : (
                            <div className="practice-test-disabled">
                                Practice Before Start
                                <img src={practiceDisabledVector} className="practice-vector" alt="Practice Test" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
);

export default AcknowledgeModal;
