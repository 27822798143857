import React, { Fragment, memo, useState } from 'react';
import { SettingsIcon } from '@100mslive/react-icons';
import { IconButton } from '@100mslive/roomkit-react';
import SettingsModal from '../Settings/SettingsModal';
const PreviewSettings = memo(() => {
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <IconButton
                data-testid="preview_setting_btn"
                css={{
                    position: 'absolute',
                    zIndex: 1,
                    bottom: '5px',
                    right: '5px',
                    borderRadius: '5px',
                    border: '1px solid #2D3440',
                    background: '#000',
                }}
                className="settings-btn"
                title="Settings"
                onClick={() => setOpen((value) => !value)}
            >
                <SettingsIcon css={{ width: '24px', height: '24px' }} />
            </IconButton>
            {open && <SettingsModal open={open} onOpenChange={setOpen} />}
        </Fragment>
    );
});

export default PreviewSettings;
